var GlobalState = {};

export function setError(setAlert, setAlertMessage, setAlertColor) {
  GlobalState.setAlert = setAlert;
  GlobalState.setAlertMessage = setAlertMessage;
  GlobalState.setAlertColor = setAlertColor;
}
export function getGlobalState() {
  return GlobalState;
}
export function ErrorHandling(message) {
  let setter = getGlobalState();
  setter.setAlert(true);
  setter.setAlertMessage(message);
  setter.setAlertColor('red');
}
export function SuccessHandling(message) {
  let setter = getGlobalState();
  setter.setAlert(true);
  setter.setAlertMessage(message);
  setter.setAlertColor('green');
}