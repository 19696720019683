import Home from "../../icons/home-icon.png";
import PoGrn from "../../icons/po-grn-list1.svg";
import BarChartIcon from "../../icons/bars-chart.svg";
import UserIcon from "../../icons/user-white.svg";
import ItemsIcon from "../../icons/quality-white.png"
import ComplaintIcon from "../../icons/complaint.svg"
import RipeningIcon from "../../icons/ripening-icon.svg"
import MangoIcon from "../../icons/fruits.svg"
import B2bIcon from "../../icons/b2b_icon.svg"
import BTDCIcon from "../../icons/btdc.png"
import BTDCApproveIcon from "../../icons/btdc-approve.png";
import UserWhiteIcon from "../../icons/user-white.svg";

export const SidebarData = [
  {
    title: "HOME PAGE",
    path: "/",
    getSlot: false,
    image: Home,
    subListClass: "hide",
    subList: [],
    permissionCheck: superUserPermissionCheck,
  },

  {
    title: "SUPPLIER PO IMAGE",
    path: "/supplier-po/",
    getSlot: true,
    image: UserWhiteIcon,
    subListClass: "hide",
    subList: [],
    permissionCheck: SupplierPermissionCheck,

  },
  {
    title: "SHORTFALL DASHBOARD",
    path: "/shortfall-dashboard/",
    getSlot: true,
    image: BarChartIcon,
    subListClass: "hide",
    subList: [],
    permissionCheck: superUserPermissionCheck,

  },
  {
    title: "PO & GRN",
    path: "",
    getSlot: false,
    image: PoGrn,
    subListClass: "hide",
    subList: [
      {
        title: "PO & GRN Listing",
        path: "/po-grn/",
        getSlot: true,
      },
      {
        title: "Generate PO",
        path: "/send-po/",
        getSlot: true,
      },
      {
        title: "Generate Multiple PO",
        path: "/send-po-excel/",
        getSlot: true,
      }, 
    ],
    permissionCheck: superUserPermissionCheck,

  },
  {
    title: "RIPENING STOCK TRANSFER",
    path: "/send-po-ripening/",
    getSlot: true,
    image: RipeningIcon,
    subListClass: "hide",
    subList: [],
    permissionCheck: superUserPermissionCheck,

  }, 
  {
    title: "PRODUCT PO DETAILS",
    path: "/product-po-detail/",
    getSlot: true,
    image: MangoIcon,
    subListClass: "hide",
    subList: [],
    permissionCheck: superUserPermissionCheck,

  }, 
  {
    title: "SUPPLIER",
    path: "/supplier/",
    getSlot: false,
    image: UserIcon,
    subListClass: "hide",
    subList: [],
    permissionCheck: superUserPermissionCheck,

  },
  {
    title: "GOODS QUALITY",
    path: "/product-quality-range/",
    getSlot: false,
    image: ItemsIcon,
    subListClass: "hide",
    subList: [],
    permissionCheck: superUserPermissionCheck,

  },
  {
    title: "SKU COMPLAINT",
    path: "",
    getSlot: false,
    image: ComplaintIcon,
    subListClass: "hide",
    subList: [
      {
        title: "Complaint Images",
        path: "/sku-complaint-image/",
        getSlot: false,
      },
      {
        title: "Admin Panel",
        path: "/complaint-admin-panel/",
        getSlot: false,
      },
    ],
    permissionCheck: superUserPermissionCheck,

  },
  {
    title: "B2B",
    path: "",
    getSlot: false,
    image: B2bIcon,
    subListClass: "hide",
    subList: [
      {
        title: "B2B PO Listing",
        path: "/b2b-po-listing/",
        getSlot: true,
      },
      {
        title: "B2B Orders",
        path: "/b2b-order-listing/",
        getSlot: true,
      },
    ],
    permissionCheck: superUserPermissionCheck,
  },
  {
    title: "BTDC Update",
    path: "/btdc-update/",
    getSlot: true,
    image: BTDCIcon,
    subListClass: "hide",
    subList: [],
    permissionCheck: BTDCPermissionCheck,
  },
  {
    title: "BTDC Approve",
    path: "/btdc-approve-reject/",
    getSlot: true,
    image: BTDCApproveIcon,
    subListClass: "hide",
    subList: [],
    permissionCheck: superUserPermissionCheck,
  },
  
];


// -------------- PERMISSION FUNCTION --------------

// super user permission
export function superUserPermissionCheck(){
  let permissionList = localStorage.getItem('permissions');
  let BTDCPermission = permissionList.includes('UEPP');
  let SuperUserPermission = permissionList.includes('BSPR');

  if(
    permissionList !== "SSV" && (
      (BTDCPermission && SuperUserPermission) || 
      (!BTDCPermission && !SuperUserPermission)
    )
  ){
    return true;
  }
  else return false;
}

// super user permission
export function BTDCPermissionCheck(){
  let permissionList = localStorage.getItem('permissions');
  if(permissionList !== "SSV" && permissionList.includes('UEPP')){
    return true;
  }
  else return false;
}

export function SupplierPermissionCheck(){
  let roleList = localStorage.getItem('role');
  if(roleList === "Supplier" || roleList.includes('Supplier')){
    return true;
  }
  return false;
}