import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import "./App.css";
import Alert from "./commonComponent/Alert/Alert";
import Routing from "./Routing";
import {setError} from './State';

export default function App() {
  const [alert, setAlert] = useState(false);
  const [alertColor, setAlertColor] = useState(false);
  // const [isLoading, setIsLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState(" Oops! ");
  setError(setAlert, setAlertMessage, setAlertColor);

  useEffect(() => {
    if (alert) {
      let timer = setTimeout(function () {
        setAlert(false);
      }, 4000);
      // clear on component unmount
      return () => {
        clearInterval(timer);
      };
    }
  }, [alert]);
  
  return (
    <>
      {/* <Loader cName={isLoading ? "show" :"hide"} /> */}
      <Routing />
      <Alert message={alertMessage} cName={alert ? "visible" :"hidden"} color={alertColor}/> 
    </>
  );
}
