import React from 'react';
import style from './Alert.module.css';
import WarningIcon from '../../icons/warning24.png';
import SuccessIcon from '../../icons/success-white.png';

function Alert(props) {
    const {cName, color} = props;
    const alertIcon = color === "red" ? WarningIcon: SuccessIcon;
    return (
        <div className={`${style.alert} ${style[cName]} ${style[color]}`}>
            <img src={alertIcon} className={style.errorImg}/>
            {props.message}
        </div>
    );
}

export default Alert;