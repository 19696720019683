import { createSlice } from "@reduxjs/toolkit";

export const PolistingSlice = createSlice({
    name: "polistingReducer", 
    initialState: {
        slotId: ''
    },
    reducers: {
        setSlotId: (state, action) => {
            let ufDate = action.payload;
            if(action.payload.length === 10){
              state.slotId = ufDate.substring(2);
            }
            else if(action.payload.length === 6){
              state.slotId = `${ufDate.substring(4)}-${ufDate.substring(2,4)}-${ufDate.substring(0,2)}`;
            }
        },
    }
})

export const { setSlotId } = PolistingSlice.actions;

export default PolistingSlice.reducer;
