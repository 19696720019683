import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import MenuIcon from "../../icons/hamgrey.svg";
import CloseIcon from "../../icons/close.png";
import { ReactComponent as Caret } from "../../icons/dropdown.svg";
import { ReactComponent as LogoutIcon } from "../../icons/logout.svg";
import { SidebarData } from "./SidebarData";
import "./NavBar.css";

function NavBar(props) {
  const navigation = useNavigate();
  const [sidebar, setSidebar] = useState(false);
  const [navList, setNavList] = useState(SidebarData)

  const showSidebar = () => setSidebar(!sidebar);

  const logout = () => {
    console.log("logout");
    localStorage.clear();
    navigation("/login");
  };

  // handling on naviation click to navigate or open sub listing
  const handleShowList = (index, item) => {

    // toggling the 'subListClass' of clicked item

    if(item.subList.length){
      let listing = Object.assign([], navList);


      // to show the given subList if it is hidden
      if(listing[index].subListClass === "hide"){
        listing[index].subListClass = "";
      }
      else{
        listing[index].subListClass = "hide";

      }

      // setting all the 'subListClass' to hide 
      // all sub listing except the one which is being toggled
      listing.forEach((element, localIdx) => {
          if(index !== localIdx)
            listing[localIdx].subListClass = "hide";
      });

      setNavList(listing);
    }
    else {
      handleLinkTo(item)
    }
  }

  // handle naviation 
  const handleLinkTo = (item) => {
    let path = item.path 
    if(item.getSlot){
      let slot = localStorage.getItem('dc_date')
      path += slot + '/'
    }
    navigation(path)
    showSidebar()
  }
  return (
    
    <div className="navContainer">

      <div className="navbar">

        <Link to="#">
          <img
            src={MenuIcon}
            onClick={showSidebar}
            className="iconDrawer"
          />
        </Link>
      </div>

      <nav className={sidebar ? "navMenu active" : "navMenu"}>

          <img
            src={CloseIcon}
            onClick={showSidebar}
            style={{ width: "2.1rem", marginBottom: "0.8rem" }}
            className={'closeBtn'}
          />
          <div className="userDetail">
            <div className="fontL capital">{localStorage.getItem("name")}</div>
            <div className="fontM">{localStorage.getItem("ename")}</div>
          </div>

          {/* user details  */}
          {navList.map((item, index) => {
            return (
              item.permissionCheck() &&
              <div key={index}>

                {/* parent listing */}
                <div 
                  onClick={() => handleShowList(index, item)} 
                  className={`d-flex justify-content-between pointer nav-item ${item.subListClass === 'hide' ? "" : "selOption"}`}
                >
                  <span>
                    <img
                      src={item.image}
                      style={{
                        width: "1.5rem",
                        marginRight: "0.6rem",
                        marginLeft: "0.3rem",
                      }}
                    />
                    <span> {item.title} </span>
                  </span>
                    {
                      !!item.subList.length && <Caret />
                    }
                  </div>


                  {/* child Listing */}
                  <div className={`${item.subListClass} subListContainer`}>
                    {
                      item.subList.length ? 
                      <>
                        {
                          item.subList.map((subItem, idx) => {
                            return(
                              <li 
                                className={`subListItem`}
                                onClick={() => handleLinkTo(subItem)}
                                key={idx}>
                                {subItem.title}
                              </li>
                            )
                          })
                        }
                      </>
                      : null
                    }
                  </div>
              </div>
            );
          })}

        <div className={`textCenter`}>
          <button className="logoutBtn" onClick={logout}>
            <LogoutIcon className="logoutIcon" /> logout
          </button>
        </div>
      </nav>
      <div
        onClick={showSidebar}
        className={sidebar ? "dummyDiv active" : "dummyDiv"}
      ></div>
    </div>
  );
}

export default NavBar;
