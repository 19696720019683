import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route, Outlet, Navigate } from "react-router-dom";
import ProtectedRoute from "./utils/ProtectedRoute";
import NavBar from "./commonComponent/Drawer/NavBar";
import Loader from "./commonComponent/Loader/Loader";
import { BTDCPermissionCheck, superUserPermissionCheck, SupplierPermissionCheck } from "./commonComponent/Drawer/SidebarData";

const Home = lazy(() => import("./screens/Home"));
const Login = lazy(() => import("./screens/Login"));
const PoListing = lazy(() => import('./screens/PoListing'))
const ReceivePo = lazy(() => import('./screens/PoListing/components/ReceivePo'))
const EditPo = lazy(() => import('./screens/PoListing/components/EditPo'))
const Invoice = lazy(() => import("./screens/PoListing/components/Invoice"));
const ReturnPo = lazy(() => import("./screens/PoListing/components/PoReturn "));
const GRN = lazy(() => import("./screens/PoListing/components/GRN"));
const SendPo = lazy(() => import("./screens/SendPo"));
const MultiplePoDemand = lazy(() => import("./screens/UploadPoExcel"));
const SendRipeningPo = lazy(() => import("./screens/SendRipeningPo"));
const Supplier = lazy(() => import("./screens/Supplier"));
const AddEditSupplier = lazy(() => import("./screens/Supplier/components/AddEditSupplier"));
const SupplierOnboarding = lazy(() => import("./screens/SupplierOnboarding"));
const ProductQualityRange = lazy(() => import("./screens/ProductQualityRange"))
const SkuComplaintImage = lazy(() => import("./screens/SkuComplaintImg"))
const ProductPoDetail = lazy(() => import("./screens/ProductPoDetail"))
const ShortfallDashboard = lazy(() => import("./screens/ShortfallDashboard"))
const B2bPoListing = lazy(() => import("./screens/B2B"))
const B2bOrder = lazy(() => import("./screens/B2B/components/B2bOrder"))
const BTDCUpdate = lazy(() => import("./screens/AppSheet/BTDCUpdate"))
const BTDCApproveReject = lazy(() => import("./screens/AppSheet/BTDCApprove"))
const ComplaintAdminPanel = lazy(() => import("./screens/SkuComplaintImg/component"))
const SupplierPoListing = lazy(() => import("./screens/SupplierPoListing"))
const SupplierPoDetails = lazy(() => import("./screens/SupplierPoListing/components/PoDetails"))

export default function Routing() {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/login" element={<Login />}></Route> */}
        <Route
          path="/login"
          element={
            <Suspense fallback={<Loader />}>
              <Login />
            </Suspense>
          }
        ></Route>
         <Route
          path="/users/distributors/supplier/details/v1/:id/"
          element={
            <Suspense fallback={<Loader />}>
              <SupplierOnboarding />
            </Suspense>
          }
        ></Route>
        <Route element={<ProtectedRoute />}>
          <Route element={<WithNavbar />}>
            <Route
              path="/"
              element={
                <Suspense fallback={<Loader />}>
                  <Home />
                </Suspense>
              }
            ></Route>

            {/* supplierpo details */}
            <Route
              path="/supplier-po/:slotId/"
              element={
                <Suspense fallback={<Loader />}>
                  <SupplierRoute component={<SupplierPoListing />}></SupplierRoute>
                </Suspense>
              }
            ></Route>

            <Route
              path="/supplier-po-detail/:supplierId/:poId/:slotId/"
              element={
                <Suspense fallback={<Loader />}>
                  <SupplierRoute component={<SupplierPoDetails />}></SupplierRoute>
                </Suspense>
              }
            ></Route>


            <Route
              path={`/po-grn/:slotId/`}
              element={
                <SuperUserRoute component={<PoListing />}></SuperUserRoute>
              }
            ></Route>
            <Route
              path="/receive-po/:slotId/:poId/"
              element={
                <SuperUserRoute component={<ReceivePo />}></SuperUserRoute>
              }
            ></Route>
            <Route
              path="/edit-po/:slotId/:poId/"
              element={
                <SuperUserRoute component={<EditPo />}></SuperUserRoute>
              }
            ></Route>
            <Route
              path="/invoice-po/:slotId/:poId/"
              element={
                <SuperUserRoute component={<Invoice />}></SuperUserRoute>
              }
            ></Route>
            <Route
              path="/return-po/:slotId/:poId/"
              element={
                <SuperUserRoute component={<ReturnPo />}></SuperUserRoute>
              }
            ></Route>
            <Route
              path="/grn/:slotId/:poId/"
              element={
                <SuperUserRoute component={<GRN />}></SuperUserRoute>
              }
            ></Route>
            <Route
              path="/send-po/:slotId/"
              element={
                <SuperUserRoute component={<SendPo />}></SuperUserRoute>
              }
            ></Route>
            <Route
              path="/send-po-excel/:slotId/"
              element={
                <SuperUserRoute component={<MultiplePoDemand />}></SuperUserRoute>
              }
            ></Route>
            <Route
              path="/send-po-ripening/:slotId/"
              element={
                <SuperUserRoute component={<SendRipeningPo />}></SuperUserRoute>
              }
            ></Route>
            <Route
              path="/product-po-detail/:slotId/"
              element={
                <SuperUserRoute component={<ProductPoDetail />}></SuperUserRoute>
              }
            ></Route>
            <Route
              path="/supplier/"
              element={
                <SuperUserRoute component={<Supplier />}></SuperUserRoute>
              }
            ></Route>
            <Route
              path="/supplier/:status/:id/"
              element={
                <SuperUserRoute component={<AddEditSupplier />}></SuperUserRoute>
              }
            ></Route>
            <Route
              path="/product-quality-range/"
              element={
                <SuperUserRoute component={<ProductQualityRange />}></SuperUserRoute>
              }
            ></Route>

            <Route
              path="/sku-complaint-image/"
              element={
                <SuperUserRoute component={<SkuComplaintImage />}></SuperUserRoute>
              }
            ></Route>

            <Route
              path="/shortfall-dashboard/:slotId/"
              element={
                <SuperUserRoute component={<ShortfallDashboard />}></SuperUserRoute>
              }
            ></Route>
            

            {/* b2b */}
            
            <Route
              path="b2b-po-listing/:slotId/"
              element={
                <SuperUserRoute component={<B2bPoListing />}></SuperUserRoute>
              }
            ></Route>

            <Route
              path="b2b-order-listing/:slotId/"
              element={
                <SuperUserRoute component={<B2bOrder />}></SuperUserRoute>
              }
            ></Route>

            {/* BTDC update (buying team dc update)  */}
            <Route
              path="btdc-update/:slotId/"
              element={
                <AppSheetRoute component={<BTDCUpdate />}></AppSheetRoute>
              }
            ></Route>

            {/* BTDC Approve reject */}
            <Route
              path="btdc-approve-reject/:slotId/"
              element={
                <SuperUserRoute component={<BTDCApproveReject />}></SuperUserRoute>
              }
            ></Route>

            {/* Complaint Admin Panel */}
            <Route
              path="complaint-admin-panel/"
              element={
                <SuperUserRoute component={<ComplaintAdminPanel />}></SuperUserRoute>
              }
            ></Route>
            
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

function WithNavbar() {
  return (
    <>
      <NavBar />
      <Outlet />
    </>
  );
}


// super user route
function SuperUserRoute({component}) {

  return (
    superUserPermissionCheck() ?
    <Suspense fallback={<Loader />}>
      {component}
    </Suspense>:
    <Navigate to="/" />
  )
}

// aap sheet permission
function AppSheetRoute({component}) {

  return (
    BTDCPermissionCheck() ?
    <Suspense fallback={<Loader />}>
      {component}
    </Suspense>:
    <Navigate to="/" />
  )
}


// supplier route 
function SupplierRoute({component}) {

  return (
    SupplierPermissionCheck() ?
    <Suspense fallback={<Loader />}>
      {component}
    </Suspense>:
    <Navigate to="/" />
  )
}