import {configureStore } from '@reduxjs/toolkit';
import HomeReducer from './HomeReducer';
import PolistingReducer from './PolistingReducer';

export default configureStore({
    reducer: {
        // tableOverview: TableOverviewReducer,
        // infoPanel: InfoPanelReducer,
        home: HomeReducer,
        poListing: PolistingReducer
    },
  });