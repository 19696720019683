import { createSlice } from "@reduxjs/toolkit";

export const HomeSlice = createSlice({
  name: "homeReducer",
  initialState: {
    header: `Welcome, ${localStorage.getItem("ename")}`,
    slotEdit: false, 
    slotId: '',

    selDist: {},
    distList: [],
    distObj: {},
  },
  reducers: {
    reset: (state, action) => {
      // return HomeSlice.getInitialState();
    },
    setSelDist: (state, action) => {
      state.selDist = action.payload;
    },
    setDist: (state, action) => {
      let dcList = [];
      for(let dc in action.payload){
        dcList.push(action.payload[dc]);
      }
      state.distList = dcList;
      state.distObj = action.payload
    },
    setHeader: (state, action) => {
      state.header = action.payload;
      if(action.payload === "Route List")  
        state.slotEdit = true;
      else 
        state.slotEdit = false
    },
    setSlotId: (state, action) => {
      let ufDate = action.payload;
      if(action.payload.length === 10){
        state.slotId = ufDate.substring(2);
      }
      else if(action.payload.length === 6){
        state.slotId = `${ufDate.substring(4)}-${ufDate.substring(2,4)}-${ufDate.substring(0,2)}`;
      }
    },

  },
});

export const { reset, setSelDist, setDist, setHeader, setSlotId  } =
  HomeSlice.actions;

export default HomeSlice.reducer;
